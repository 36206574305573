.container {
    width: 96%;
    margin-right: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chartContainer  {
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100%;
    color: grey;
    background-color: var(--lightblue);
    margin-top: 1rem;
    padding-bottom: 1rem;
}

.tableRow {
    width: 100%;
}

.tableContainer  {
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: grey;
    background-color: var(--lightblue);
    margin-top: 1rem;
    padding-bottom: 1rem;
}


.chartLabel {
    color: var(--slate);
    font-family: Inter, Georgia;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
}
.dropdownContainer  {
    border-radius: 1rem;
    width: 80%;
    height: 30%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    color: grey;
    background-color: var(--tatami);
    margin: 10%;
    font-family: Inter, Georgia;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    padding: 2%;
}

.deleteDashboardButton  {
    margin-top: 3rem;
    border-radius: 1rem;
    background-color: var(--peach);
    padding-top: 1rem;
    padding-bottom: 1rem;
}



.dashboardContainer {
    align-items: center;
    height: 100%;
    background-color: var(--slate);
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.228) 3px 9px 1px;
    margin-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 3rem;
    border: 5px;
    border-color:rgb(88, 247, 173);
    border-style: solid;
    }

.dashboardTitleContainer {
    background-color:var(--tatami);
    border-radius: 1rem;
    margin: 1rem;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: center;
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.228) 3px 9px 1px;
    border-radius: 3rem;
    border: 5px;
    border-color:rgb(83, 88, 86);
    border-style: solid;
    color: var(--slate);
    font-family: Inter, Georgia;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
}