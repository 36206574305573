
:root {
    --box-height: 13vh;
}

.paragraphCard {
    position: relative;
    overflow: scroll;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    border-width: 0px;
    border-color: var(---tatami);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px;
}
.paragraphCard:hover {
    border-color: var(--tatami);
    transform: scale(1.005);
}

.h3 {
    
    font-family: "Georgia", sans-serif;
    color: var(--slate) !important;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-transform: none !important;
}

.levelLabel {
    font-family: "Georgia", sans-serif;
    color: var(--truewhite) !important;
    font-size: 1rem;
    line-height: 0.5;
}

.numLabel {
    font-family: "Georgia", sans-serif;
    color: var(--truewhite) !important;
    font-size: 2rem;
    line-height: 0.5;
}

.img {
    border: 0px solid #ddd;
    min-height: 100px;
    max-width: 100%;
    max-height: 300px;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 2rem;

  }

.chartContainer  {
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    /* color: grey; */
    margin-top: 1rem;
    /* margin: 5%; */
    /* margin-left: 1rem; */
    /* padding-bottom: 1rem; */
    /* border-color: var(---tatami);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px; */
}

.levelCountContainer {
    /* position:relative;
    top: -15px;
    right: -5px; */
    margin-bottom: 0.5rem;
    align-items: center;
    height: var(--box-height);
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    border-radius: 1rem;
    border-width: 1px;
    border-color: var(--slate);
    background-color: var(--peach);
    box-shadow: rgba(255, 255, 255, 0.389) 3px 1px 3px inset,
    rgba(46, 54, 80, 0.277) 2px 3px 3px;
}

.questionCountContainer {
    /* position:relative;
    top: -15px;
    right: 30px; */
    align-items: center;
    height: var(--box-height);
    width: 100%;
    margin-bottom: 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    border-radius: 1rem;
    border-width: 1px;
    border-color: var(--slate);
    background-color: var(--mint);
    box-shadow: rgba(255, 255, 255, 0.389) 3px 1px 3px inset,
    rgba(46, 54, 80, 0.277) 2px 3px 3px;
}


.titleBox {
    /* position:relative;
   top: -15px;
   right: 30px; */
   align-items: center;
   height: var(--box-height);
   width: 100%;
   margin-bottom: 0.5rem;
   text-align: center;
   display: flex;
   flex-direction: row;
   justify-content: center; 
   border-radius: 1rem;
   border-width: 1px;
   border-color: var(--slate);
   background-color: var(--tatami);
   box-shadow: rgba(255, 255, 255, 0.389) 3px 1px 3px inset,
   rgba(46, 54, 80, 0.277) 2px 3px 3px;
}


.vocabCountContainer {
   /* position:relative;
   top: -15px;
   right: 30px; */
   align-items: center;
   height: var(--box-height);
   width: 100%;
   margin-bottom: 0.5rem;
   text-align: center;
   display: flex;
   flex-direction: row;
   justify-content: center; 
   border-radius: 1rem;
   border-width: 1px;
   border-color: var(--slate);
   background-color: var(--slate);
   box-shadow: rgba(255, 255, 255, 0.389) 3px 1px 3px inset,
   rgba(46, 54, 80, 0.277) 2px 3px 3px;
}

.languageTitle {
    font-family: "Georgia", sans-serif;
    color: var(--darkpeach) !important;
    font-size: 2.0rem;
    line-height: 1;
}

.languageSubtitle {
    font-family: "Georgia", sans-serif;
    color: var(--peach) !important;
    font-size: 1.5rem;
    line-height: 0.5;

}


.countContainer {
    align-items: center;
    height: 10vh;
    width: 10vh;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    border-radius: 2rem;
    border-color: var(---tatami);
    background-color: var(--lightmint);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px;
}

.barChartBackground {
    background-color: var(--lightmint);
    padding-top: 1rem;
    border-radius: 2rem;
    border: 1px solid;
}

.donutContainer {
    align-items: center;
    
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    border-radius: 2rem;
    border: none;
/*    
    border-color: var(---tatami);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px; */
}

.headerDiv {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    padding-bottom: 0%;
    
  }
  
  .chartTitle {
    font-family: Helvetica;
    color: var(--slate) !important;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-transform:capitalize !important;
  }

.svgContainer {
    display: inline-block;
    position: relative;
    width: 50%;
    margin: 1rem;
    padding-bottom: 100%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
  }
.svgContentResponsive {
    display: inline-block;
    position: absolute;
    margin: 1rem;
    top: 10px;
    left: 0;
  }
