.img {
    border: 0px solid #ddd;
    max-width: 100%;
    max-height: 450px;
    margin-bottom: 1rem;

  }

.container {
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100%;
    color: grey;
    background-color: var(--lightblue);
    padding-bottom: 1rem;
    border-radius: 3rem;
}
