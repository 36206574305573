:root {
    --level-button-width : 40px;
    --level-button-margin: 5px;
    --level-button-height: 20%;
}

@keyframes bounce-5 {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-10px); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-7px); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}

@keyframes moveInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

.buttonContainer {
  max-width: 10px !important;

}

.levelButton {
    /* width: 100px !important; */
    background-color: var(--blue);
    margin: var(--level-button-margin); 
    justify-items: center;
    border-radius: 3px;
    color: var(--blue);
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    animation-name: moveInFromLeft;
    animation-duration: 0.5s;
}

.currentLevelButton {
  /* width: 100px !important; */
  background-color: var(--mint);
  margin: var(--level-button-margin); 
  justify-items: center;
  border-radius: 3px;
  color: var(--blue);
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  animation-name: moveInFromLeft;
  animation-duration: 0.5s;
}

.levelButton:hover {
    background-color: var(--mint);
    color: rgb(210, 210, 225)
}

.levelButton:focus {
    background-color: var(--mint);
    color: rgb(210, 210, 225)
}

.levelButton:disabled {
    background-color: rgb(89, 89, 107);
    color: rgb(89, 89, 107)
}

.levelButton:disabled:hover {
    background-color: rgb(19, 19, 165);
    color: rgb(210, 210, 225)
}

.levelBarContainer {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    align-items: center;
    
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
}


.tooltip {
    visibility: hidden;  /* Or display: none, depending on how you want it to behave */
  }
  
  .tooltip-on-hover:hover + .tooltip {    /* Uses the adjacent sibling selector */
    visibility: visible;  /* Or display: block */
  }
  
  .hintText {
    color: var(--blue);
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
  }