.container {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
}

.classroomFormContainer {
  padding: 2rem;
  margin: 1rem;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  border-radius: 1rem;
  border:1px solid rgb(88, 247, 173);
  background-color: var(--tatami);
  align-items: left;
  justify-content:left;

  
}

.classroomContainer {
  width: 100%;
  background-color:var(--lightmint);
  border-radius: 1rem;
  height: 100%;
  justify-items: center;
  padding: 1rem;
  align-items: center;
  height: 100%;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.228) 3px 9px 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;
 
  border: 5px;
  border-color:rgb(88, 247, 173);
  border-style: solid;
}
.classroomHeader {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 2rem;
}
.questionsContainer {
  width: 100%;
  background-color:var(--lightmint);
  border-radius: 1rem;
  height: 100%;
  justify-items: center;
  padding: 1rem;
  align-items: center;
  height: 100%;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.228) 3px 9px 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;
 
  border: 5px;
  border-color:rgb(88, 247, 173);
  border-style: solid;
}

.studentsContainer {
  width: 100%;
  background-color:var(--lightmint);
  border-radius: 1rem;
  height: 100%;
  justify-items: center;
  padding: 1rem;
  align-items: center;
  height: 100%;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.228) 3px 9px 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;
 
  border: 5px;
  border-color:rgb(88, 247, 173);
  border-style: solid;
}

.letsStudyButton {
  background-color: rgba(24, 164, 33, 0.657);
  overflow: scroll;
  margin: 5%;
  border-radius: 1rem;;
  border-width: 3px;
  border-color: rgba(118, 118, 220, 0.964);
  box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
  rgba(46, 54, 80, 0.277) 2px 10px 3px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

}
.letsStudyButton:hover {
  background-color:  rgba(24, 164, 33, 0.657);
  overflow: scroll;
  margin: 5%;
  border-radius: 10rem;
  border-width: 3px;
  border-color: rgba(19, 19, 231, 0.964);
  box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
  rgba(46, 54, 80, 0.277) 2px 10px 3px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  transform: scale(1.05);
}

.container {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chartContainer  {
  
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 100%;
  color: grey;
  background-color: blanchedalmond;
  padding: 1rem;
  
}

.dropdownContainer  {
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  color: grey;
  background-color: blanchedalmond;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dashboardContainer {
  align-items: center;
  height: 100%;
  background-color: var(--slate);
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.228) 3px 9px 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 3rem;
  border: 5px;
  border-color:rgb(88, 247, 173);
  border-style: solid;
  }

.dashboardTitleContainer {
  background-color: blanchedalmond;
  border-radius: 1rem;
  margin: 1rem;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.228) 3px 9px 1px;
  border-radius: 3rem;
  border: 5px;
  border-color:rgb(83, 88, 86);
  border-style: solid;
  color: #745a5a;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.backToClassroomsButton {
  background-color: var(--peach);
  border-radius: 1rem;
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
}

.deletefromClassroomButton {
  background-color: var(--peach);
}

.updateButton {
  background-color: var(--mint);
}

.myQuestionsButton {
  margin-top: 1rem;
  border-radius: 1rem;
  background-color: var(--mint);
}

