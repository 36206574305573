.classroomCard {
    height: 10rem;
    overflow: scroll;
    margin: 5%;
    padding: auto;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgba(118, 118, 220, 0.964);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px;
}

.classroomCard:hover {
    height: 10rem;
    overflow: scroll;
    margin: 5%;
    padding: auto;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgba(220, 149, 118, 0.964);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px;
    transform: scale(1.05);
}