.nav {
    height: 10% !important;
    padding: 4% !important;
    justify-items: center !important;
    display: flex;
    
  }
  
.navlink {
    font-family: "Roboto", sans-serif;
    color: burlywood !important;
  }
  
.header {
    padding-bottom: 0;
    background-color:  rgb(255,141,122);
    width: 50%;
    height: 100%;
    display: flex;
    border-bottom-right-radius: 4rem;
 }

.container {
    width: 100% !important;
    height: 100%;
    padding-top: 0 !important;
    align-self: flex-end;
}
.scrollContainer {
  width: 90%;
  margin: 5%;
}

.paragraphCard {
  position: relative;
  overflow: scroll;
  margin-left: 0%;
  margin-top: 1rem;
  margin-right: 0%;
  margin-bottom: 2rem;
  padding: 0.5rem;
  border-radius: 1rem;
  border-width: 0px;
  border-color: var(---tatami);
  background-color: var(--shadow);
  box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
  rgba(46, 54, 80, 0.277) 2px 10px 3px;
}
.paragraphCard:hover {
  border-color: var(--tatami);
  transform: scale(1.000);
}

.h3 {
  font-family: "Georgia", sans-serif;
  color: var(--slate) !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-transform: none !important;
}

.headerDiv {
width: 80%;
margin-right: 10%;
margin-left: 10%;
display: flex;
flex-direction: row;
justify-content: center;
justify-items: center;
padding-bottom: 0%;

}
