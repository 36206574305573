.nav {
    height: 10% !important;
    padding: 4% !important;
    justify-items: center !important;
    display: flex;
    
  }
  
.navlink {
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(87, 72, 53) !important;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
.header {
    padding-bottom: 0;
    background-color:  rgb(255,141,122);
    width: 80%;
    height: 100%;
    display: flex;
    border-bottom-right-radius: 4rem;
 }

.container {
    width: 100% !important;
    height: 100%;
    padding-top: 0 !important;
    align-self: flex-end;
}
.scrollContainer {
  width: 90%;
  margin: 5%;
}