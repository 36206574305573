.paragraphCard {
    width: 100%;
    overflow: scroll;
    display: flex !important;
    flex-direction: column !important;
    /* align-items: center; */
    margin-bottom: 1rem;
    padding: 2rem;
    padding-top: 0;
    padding-bottom:0;
    border-radius: 1rem;
    border-width: 0px;
    border-color: var(---tatami);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px;
    background-color:var(--lightmint);
}   
/* .paragraphCard:hover {
    border-color: var(--tatami);
    transform: scale(1.005);
} */

.paragraphHeader {
    text-transform:none !important;
    font-family:Helvetica;
    color: var(--slate) !important;
    font-size: 2rem;
    font-weight: 200;
    line-height: 1.2;
    

}

.h3 {
    
    font-family: Helvetica;
    color: var(--slate) !important;
    font-size: 1.5rem;
    font-weight: 100 !important;
    line-height: 1.2;
    text-transform: none !important;
    margin-top: 20;
}

.img {
    border: 0px solid #ddd;
    min-height: 100px;
    max-width: 100%;
    max-height: 300px;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 2rem;

  }

/* //'http://lebebetabackend-env.eba-mjc226az.us-east-1.elasticbeanstalk.com' */