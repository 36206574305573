
.paragraphCard {
    overflow: scroll;
    margin: 5%;
    padding: 1rem;
    border-radius: 1rem;
    border-width: 0px;
    border-color: 2px solid black;
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px;
}
.paragraphCard:hover {
    border-color: var(--tatami);
    transform: scale(1.05);
}



.img {
    border: 0px solid #ddd;
    min-height: 100px;
    max-width: 100%;
    max-height: 300px;
    padding: 1rem;
    margin: 1rem;
    border-radius: 2rem;

  }

.chartContainer  {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: grey;
    border-width: 15px;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.donutContainer {
    align-items: center;
    min-height: 16rem;
    margin: 1rem;
    padding-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    border-radius: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.svgContainer {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
  }
.svgContentResponsive {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
  }

.countContainer {
    align-items: center;
    min-height: 16rem;
    margin: 1rem;
    padding: 3px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    border-radius: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.tableContainer {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: grey;
    border-width: 15px;
    padding: 5%;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.trickyWordsHeader {
    font-family: Inter, Georgia;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.5;
}
.trickyWordText {
    font-family: Inter, Georgia;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
}

.fluencyText {
    color: var(--mint);
    font-family: Inter, Georgia;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;

}
.trickyWordContainer {
    background-color: var(--peach);
    padding: 2%;
    margin:2%;
    min-width: 96%;
    border-radius: 2rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    
}