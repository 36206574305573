.questionContainer {
  height: 100%;
  width: 100%;
}



.textCard {
  background-color: rgba(76, 74, 71, 0);
  border: 0px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.buttonsCorrect {
  appearance: button;
  background-color: #4c4a47;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  border: 2px solid #c9ab14;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}
.buttonsCorrect:active {

  background-color: var(--mint);

}

.buttonsIncorrect {
  appearance: button;
  background-color: rgb(76, 74, 71);
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  border: 1px solid #c9ab14;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.buttonsIncorrect:active {
  background-color: var(--peach);
}




.giveUpButton {
  appearance: button;
  background-color: rgb(75, 71, 76);
  background-image: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.15),
  rgba(255, 255, 255, 0)
  );
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #caa7a7;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.giveUpButton:disabled {
  background-color: rgb(75, 71, 76);
  color: #4f4646;
}