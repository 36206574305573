.container {
  width: 96%;
  margin-left: 2%;
  margin-right: 2%
}

@keyframes bounce-5 {
  0%   { transform: scale(1,1)    translateY(0); }
  10%  { transform: scale(1.01,.90) translateY(0); }
  30%  { transform: scale(.99,1.01) translateY(-1px); }
  50%  { transform: scale(1,1)    translateY(0); }
  57%  { transform: scale(1,1)    translateY(-1px); }
  64%  { transform: scale(1,1)    translateY(0); }
  100% { transform: scale(1,1)    translateY(0); }
}

.divMobile {
  align-items: center;
  position: relative;
  min-height: 110vh;
  background-color: var(--slate);
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.228) 3px 9px 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 3rem;
  border: 5px;
  border-color:rgb(88, 247, 173);
  border-style: solid;
  animation-name: bounce-5;
  animation-duration: 1s;
  padding-bottom:10%;
  }

.div {
  align-items: center;
  position: relative;
  min-height: 95vh;
  background-color: var(--slate);
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.228) 3px 9px 1px;
  margin-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 3rem;
  border: 5px;
  border-color:rgb(88, 247, 173);
  border-style: solid;
  animation-name: bounce-5;
  animation-duration: 1s;
  padding-bottom:10%;
  }

.loaderContainter {
  height: 55vh;
  
}

.divider {
  height: 0;
  width: 15rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  border-bottom: 4px solid;
  
}

.instructions {
  color:var(--lightpeach);
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  text-transform: none;
}

.exitButton {
  appearance: button;
  background-color: rgb(75, 71, 76);
  background-image: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.15),
  rgba(255, 255, 255, 0)
  );
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #caa7a7;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.skipButton {
  appearance: button;
  background-color: rgb(75, 71, 76);
  background-image: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.15),
  rgba(255, 255, 255, 0)
  );
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #caa7a7;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.skipButton:disabled {
  background-color: rgb(75, 71, 76);
  color: #4f4646;
}

.flagButton {
  appearance: button;
  background-color: rgb(75, 71, 76);
  background-image: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.15),
  rgba(255, 255, 255, 0)
  );
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #caa7a7;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.nextButton {
  appearance: button;
  background-color: rgb(75, 71, 76);
  background-image: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.15),
  rgba(255, 255, 255, 0)
  );
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #caa7a7;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.nextButton:disabled {
  background-color: rgb(75, 71, 76);
  color: #4f4646;
}

.donutdiv {
  position: absolute; 
  top: -15px; 
  right: 5px; 
  background-color: rgba(76, 74, 71, 0);
  border-radius: 3rem;
  margin-top: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border:3px;
  text-align: center;
  display: flex;
  flex-direction: column;
}


.classroomTitleContainer {
  background-color: rgb(219, 183, 121);
  border-radius: 3rem;
  height: 100%;
  justify-items: center;
  padding: 1%;
  align-items: center;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.228) 3px 9px 1px;
  border-radius: 3rem;
  border: 5px;
  border-color:rgb(88, 247, 173);
  border-style: solid;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
}

.backToClassroomButton {
    background-color: rgb(55, 155, 55);
    margin-top: 1rem;
    width: 60%;
    display: flex;
    border-radius: 1rem;
    border-width: 1px;
    border-color: rgb(2, 39, 2);
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

.backToClassroomButton:hover {
  background-color: rgb(55, 155, 55);
  margin-top: 1rem;
  width: 60%;
  display: flex;
  border-radius: 1rem;
  border-width: 1px;
  border-color: rgb(2, 39, 2);
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.075) 3px 5px 1px;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  transform: scale(1.05)
}

.levelLabel {
  position: absolute;
  font-size: 1.4rem;
  color: var(--tatami);
  font-weight: 500;
  line-height: 1.5;
  text-align: center;

  
}

.languageLabel {
  font-size: 1.4rem;
  color: var(--tatami);
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  font-family: Georgia, sans-serif;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.otherButtonsContainer {
  position: absolute; 
  top: 83%; 
  right: 50;
}

.levelLabel2 {
  font-size: 1rem;
  color: var(--tatami);
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  font-family: Georgia, sans-serif;
  margin-top:-1rem;
}

.loginContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom:0;
  padding:0 !important;
}

.loginText {
  color: var(--blue);
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
}
