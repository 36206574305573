:root {
    --orange: rgb(250,175,77);
    --peach: rgb(255,141,122);
    --tatami: rgb(248,238,228);
    --blue: rgba(115,129,197);
    --mint: rgb(47, 185, 134);
    --darkmint: rgba(29, 93, 70, 0.542);
    --lightmint: rgb(194, 231, 218);
    --slate: rgb(54,54,59);
    --shadow: rgba(54, 54, 59, 0.671);
    --thickshadow: rgb(102, 102, 107);
    --offwhite: rgb(196, 234, 196);
    --truewhite: rgb(239, 249, 239);
    --lightpeach: rgb(222, 211, 210);
    --darkpeach: rgb(137, 112, 108);
    --lightblue: rgb(158, 206, 225);
  }
  
  
  .container {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  
  }
  
  .landingContainer {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  
  }
  
  .languageButtonContainer {
    /* border-radius: 3rem;
    box-shadow: rgba(126, 115, 115, 0.15) 0 1px 0 inset,
      rgba(46, 54, 80, 0.075) 0 1px 1px;
    font-family: Inter, sans-serif;
    background-color: rgb(222, 188, 226);
    border: 1px solid #dcdbec; */
    align-items: center;
    text-align: center;
    justify-content: center;
    /* font-weight: 500;
    line-height: 1.5;
    font-size: 1.5rem; */
    padding: 1px;
    display: flex;
    /* margin-bottom: 20%; */
    justify-items: center;
    width: 100%;
  }
  
  .dropdown {
    margin: 0.3rem;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
  }
  
  .col {
    
    justify-content: center;
    justify-items: center !important;
    display: flex !important;
    align-items: center !important;
    width: 100%;
  }
/*   
  .startButton {
    background-color: var(--start-button-background);
    border: 0;
    border-color: var(--start-button-border);
    border-radius: 2rem;
    color: var(--start-button-text-color);
    cursor: pointer;
    box-shadow: var(--start-button-shadow) 0 1px 0 inset,
      var(--start-button-shadow) 0 3px 10px;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 600;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    justify-items: center;
    width: 10rem;
    height: 10rem;
    outline: 0;
    margin-bottom: 10%;
    margin-top: 10%;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .startButton:before {
    background-color: initial;
    background-image: linear-gradient(var(--start-button-top-gradient) 0, var(--start-button-bottom-gradient) 70%);
    border-radius: 2rem;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .3;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }
  
  .startButton:hover {
    background-color: var(--start-button-hoverbackground);
    border-color: var(--start-button-hoverborder);
    box-shadow: var(--start-button-hovershadow) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(167, 22, 22, 0.1) 0 10px 13px;
    transform: scale(1.05);
  }
  
  @media (min-width: 768px) {
    .startButton {
      padding: 16px 48px;
    }
  } */
  
.letsGoButtonContainer {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  
}

.dropdownContainer {
    border-radius: 3rem;
    /* box-shadow: var(--container-shadow) 0 1px 0 inset,
      rgba(46, 54, 80, 0.075) 0 1px 1px; */
    height: 4rem;
    color:var(--slate);
    font-family: Inter, Georgia, 'Times New Roman', Times, serif;
    background-color: var(--offwhite);
    border: 3px solid var(--mint);
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.5rem;
    display: flex;
    justify-items: center;
    width: 60%;
    padding:0.2rem;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 0.5rem;
    margin-top: 0;
    
  }
.paragraphCard {
    position: relative;
    overflow: scroll;
    margin-left: 5%;
    margin-top: 1rem;
    margin-right: 5%;
    margin-bottom: 2rem;
    padding: 0.5rem;
    border-radius: 1rem;
    border-width: 0px;
    border-color: var(---tatami);
    background-color: var(--shadow);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px;
}
.paragraphCard:hover {
    border-color: var(--tatami);
    transform: scale(1.000);
}

.h3 {
    font-family: "Georgia", sans-serif;
    color: var(--slate) !important;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-transform: none !important;
}

.headerDiv {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  padding-bottom: 0%;
  
}

.whichLanguageHeader {
  font-family: Georgia;
  color: var(--thickshadow) !important;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: none !important;
}

.greetingHeader {
  font-family: Helvetica;
  color: var(--darkmint) !important;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: none !important;
}


.languageLabel {
    font-family: "Georgia", sans-serif;
    color: var(--peach) !important;
    font-size: 2.5rem;
    line-height: 1;
}

.xAxisLabel {
  font-family: "Times New Roman", sans-serif;
  color: var(--darkpeach) !important;
  font-size: 0.9rem;
  line-height: 0.5;
  text-align: center;
}

/* .totalCountLabel {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: var(--darkpeach) !important;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  text-transform: none;
} 

.totalNumberLabel {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: var(--mint) !important;
  font-size: 2.5rem;
  line-height: 0;
  text-align: center;
} */

.img {
    border: 0px solid #ddd;
    min-height: 100px;
    max-width: 100%;
    max-height: 300px;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 2rem;

  }

.letsGoButton {
    background-color: var(--mint);
    border: 0;
    border-color: var(--start-button-border);
    border-radius: 2rem;
    color: var(--start-button-text-color);
    cursor: pointer;
    box-shadow: var(--shadow) 0 1px 0 inset,
      var(--thickshadow) 3px 10px 0px;
    color: var(--white);
    font-family: Georgia;
    font-size: 3rem;
    font-weight: 600;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    justify-items: center;
    width: 90%;
    margin-top: 0rem;
    margin-bottom: 2rem;
    margin-left: 5%;
    margin-right: 5%;
    height: 4rem;
    outline: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .letsGoButton:hover {
    transform: scale(1.05);
    background-color: var(--mint)
  }

  .loginContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom:0;
    padding:0 !important;
  }
  
  .loginText {
    color: var(--blue);
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }
  