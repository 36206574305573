.img {
  border: 0px solid #ddd;
  max-width: 100%;
  max-height: 450px;
  margin-bottom: 1rem;

}

.container {
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 100%;
  color: grey;
  background-color: rgba(248, 238, 228, 0);
  padding-bottom: 1rem;
  border-radius: 3rem;
}

.letsGoButton {
  background-color: var(--mint);
  border: 0;
  border-color: var(--slate);
  border-radius: 2rem;
  color: var(--lightpeach);
  cursor: pointer;
  box-shadow: var(--start-button-shadow) 0 1px 0 inset,
    var(--start-button-shadow) 0 3px 10px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-items: center;
  width: 10rem;
  height: 5rem;
  outline: 0;
  margin-bottom: 10%;
  
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.letsGoButton:hover {
  transform: scale(1.05)
}
