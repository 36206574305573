.levelModal {
    background-color: firebrick !important;
    border-radius: 3rem !important;
}

@keyframes bounce-5 {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.01,.90) translateY(0); }
    30%  { transform: scale(.99,1.01) translateY(-1px); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-1px); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
  }

.modalDiv {
    border-radius: 3rem !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    max-width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
    color: grey;
    background-color: blanchedalmond;
    animation-name: bounce-5;
    animation-duration: 0.5s;
}


.modalTitle {
    font-style: Georgia;
    margin: 1rem; 
}

.modalSubTitle {
    font-style: Georgia;
    margin: 1rem; 
    font-size: 1rem;
}


.keepGoingButton {
    background-color: var(--mint);
    border-radius: 1rem;
}
.keepGoingButton:hover {
    transform: scale(1.05);
}
.takeABreakButton {
    background-color: var(--lightpeach);
    border-radius: 1rem;
}


.continueButton {
    font-style: Georgia;
    margin: 1rem; 
    font-size: 1rem;
}
