.levelModal {
    background-color: firebrick !important;
    border-radius: 3rem !important;
}

@keyframes bounce-5 {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.01,.90) translateY(0); }
    30%  { transform: scale(.99,1.01) translateY(-1px); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-1px); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
  }

.modalDiv {
    border-radius: 3rem !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    max-width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 2%;
    color: grey;
    background-color: rgba(106, 130, 122, 0.709);
    animation-name: bounce-5;
    animation-duration: 0.5s;
}

.flagForReviewButton {
    background-color: var(--peach);
    border-radius: 1rem;
}

.nevermindButton {
    background-color: var(--lightpeach);
    border-radius: 1rem;
}

.commentBox {
    background-color: var(--lightmint);
    border-radius: 1rem;
}

.optionButton {
    background-color: var(--lightpeach);
    border-radius: 1rem;
    color: var(--slate)
}

.checkBox {
    background-color: var(--peach);
    border-radius: 1rem;
}

.modalTitle {
    font-style: Georgia;
    color:aliceblue
    
}

.modalSubTitle {
    font-style: Georgia;
    margin: 1rem; 
    font-size: 1rem;
    color:mintcream;
}

.continueButton {
    font-style: Georgia;
    margin: 1rem; 
    font-size: 1rem;
}
