:root {
    --score-box-width : 35px;
    --score-bar-margin: 5px;
}

@keyframes moveInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

@keyframes bounce-5 {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-10px); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-7px); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}

.scoreContainer {
    margin-top: 5px;
    margin-bottom:5px;
    display: flex;
    flex-direction: row;
}

.scoreBoxNeutral {
    background-color: gray;
    height: 2%;
    width: var(--score-box-width);
    margin: var(--score-bar-margin);
    border-radius: 3px;
}

.scoreBoxNeutral:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

.scoreBoxCorrect {
    background-color: var(--mint);
    height: 2%;
    width: var(--score-box-width);
    margin: var(--score-bar-margin);
    border-radius: 3px;
}

.scoreBoxCorrectBounce {
    background-color: var(--mint);
    height: 2%;
    width: var(--score-box-width);
    margin: var(--score-bar-margin);
    border-radius: 3px;
    animation-name: bounce-5;
    animation-duration: 0.5s;
}

.scoreBoxCorrectBounce:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.scoreBoxCorrect:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.scoreBoxIncorrect {
    background-color: var(--peach);
    height: 2%;
    width: var(--score-box-width);
    margin:var(--score-bar-margin);
    border-radius: 3px;
}

.scoreBoxIncorrect:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.scoreBoxIncorrectBounce {
    background-color: var(--peach);
    height: 2%;
    width: var(--score-box-width);
    margin: var(--score-bar-margin);
    border-radius: 3px;
    animation-name: bounce-5;
    animation-duration: 0.5s;
}

.scoreBoxIncorrectBounce:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
.scoreBoxPartial {
    background-color: rgb(228, 192, 13);
    height: 2%;
    width: var(--score-box-width);
    margin: var(--score-bar-margin);
    border-radius: 3px;
}

.scoreBoxPartial:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

