.img {
    border: 0px solid #ddd;
    border-radius: 4px;
    width: 300px;
  }

.container {
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    padding: 1rem;
    align-items: center;
    height: 100%;
    color: grey;
    background-color: blanchedalmond;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 3rem;
}