.input {
  width: 100%;
  height: 50px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
  display: flex;
  margin-top: 2rem;
}

.questionContainer {
  height: 100%;
  width: 100%;
}

.questionContainerMobile {
  height: 100vh;
  width: 100%;
}

.loaderContainer {
  min-height: 20vh;
  max-height: 100%;
  width: 100%;
  margin-top: 20%;
}


.textCard {
  background-color: rgba(76, 74, 71, 0);
  border: 0px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.tooltip {
  visibility: hidden;  /* Or display: none, depending on how you want it to behave */
}

.tooltip-on-hover:hover + .tooltip {    /* Uses the adjacent sibling selector */
  visibility: visible;  /* Or display: block */
}

.hintText {
  color: var(--blue);
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
}

.tableCard {
  background-color: rgba(76, 74, 71, 0);
  border: 0px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 0;
}

.accentButton {
  appearance: button;
  background-color: rgb(75, 71, 76);
  background-image: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.15),
  rgba(255, 255, 255, 0)
  );
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #caa7a7;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}


.giveUpButton {
  appearance: button;
  background-color: rgb(75, 71, 76);
  background-image: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.15),
  rgba(255, 255, 255, 0)
  );
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
  rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #caa7a7;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0.3rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.giveUpButton:disabled {
  background-color: rgb(75, 71, 76);
  color: #4f4646;
}