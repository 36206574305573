

:root {

  --start-button-border:var(--tatami);
  --start-button-background:var(--mint); 
  --start-button-top-gradient:var(--tatami);
  --start-button-bottom-gradient:rgba(248, 238, 228, 0.439);;
  --start-button-border:var(--tatami);
  --start-button-shadow: var(--tatami);
  --start-button-text-color:var(--tatami);
  --start-button-hoverbackground:var(--blue);
  --start-button-hoverborder:var(--mint);
  --start-button-hovershadow:var(--blue);
}



.container {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

}

.col {
  
  justify-content: center;
  justify-items: center !important;
  display: flex !important;
  align-items: center !important;
  width: 100%;
}

.numberOfQuestionsHeader {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  padding-bottom: 0%;
}

.numberOfQuestions {
  font-family: 'Helvetica';
  color: var(--white) !important;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-transform:none !important;
}

.headerDiv {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  padding-bottom: 0%;
  
}

.whichLanguageHeader {
  font-family: Helvetica;
  color: var(--white) !important;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1;
  text-transform: none !important;
}

.tooltip {
  visibility: hidden;  /* Or display: none, depending on how you want it to behave */
}

.tooltip-on-hover:hover + .tooltip {    /* Uses the adjacent sibling selector */
  visibility: visible;  /* Or display: block */
}

.landingContainer {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

}

.languageButtonContainer {
  /* border-radius: 3rem;
  box-shadow: rgba(126, 115, 115, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px;
  font-family: Inter, sans-serif;
  background-color: rgb(222, 188, 226);
  border: 1px solid #dcdbec; */
  align-items: center;
  text-align: center;
  justify-content: center;
  /* font-weight: 500;
  line-height: 1.5;
  font-size: 1.5rem; */
  display: flex;
  flex-direction: column;
  /* margin-bottom: 20%; */
  justify-items: center;
  width: 100%;
}

.dropdown {
  margin: 0.3rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
}



.startButton {
  background-color: var(--blue);
  
  border: 0;
  border-color: var(--peach);
  border-radius: 2rem;
  color: var(--lightpeach);
  cursor: pointer;
  box-shadow: var(--shadow) 0 1px 0 inset,
    var(--shadow) 0 3px 10px;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-items: center;
  width: 10rem;
  height: 8rem;
  outline: 0;
  margin-bottom: 2rem;
  margin-top: 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .1s;
  touch-action: manipulation;
}

.startButton:before {
  background-color: initial;
  background-image: linear-gradient(var(--tatami) 0, rgba(248, 238, 228, 0.439) 50%);
  border-radius: 2rem;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .2;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.startButton:hover {
  background-color: var(--mint);
  border-color: var(--peach);
  box-shadow: var(--mint) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(167, 22, 22, 0.1) 0 10px 13px;
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .startButton {
    padding: 16px 48px;
  }
}
